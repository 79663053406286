<template>
  <div id="StoreInfo">
    <div class="blueTitle">商店说明</div>

    <div class="infoBlock">
      <div class="dataRow" v-for="(it, key) in infoRows" :key="key">
        <div>
          {{ it.title }}
        </div>
        <div v-if="it.key === 'preferred_type' && it.value !== ''">
          {{ showPreferredLots(it.value) }}
        </div>
        <div v-else>
          {{ it.value }}
        </div>
      </div>
    </div>

    <div class="blueTitle">近期战绩（30天内）</div>

    <template v-if="statRows !== null">
      <div class="statRow" v-for="(it, key) in statRows" :key="key">
        <img :src="showImg(it.lotterycode)" alt="" />
        <div class="statRowCol lotCol">{{ it.lotteryname }} ：</div>
        <div class="statRowCol">
          <span>{{ it.total_issue_count_by_30d }}期中</span>
          <span>{{ it.hit_issue_count_by_30d }}期</span>
        </div>
        <div class="statRowCol">
          <span>准确率</span>
          <span class="winText">{{ it.hit_rate_by_30d }}％</span>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="statRow" style="justify-content: center"> 近期30天内无战绩 </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'StoreIntro',
  props: {
    shopInfo: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  created() {},
  computed: {
    infoRows() {
      const arr = [
        {
          key: 'shopname',
          title: '我的商店名称 ：',
          value: null,
        },
        {
          key: 'introduction',
          title: '商店简介 ：',
          value: null,
        },
        {
          key: 'preferred_type',
          title: '擅长的彩种 ：',
          value: null,
        },
        {
          key: 'follower',
          title: '粉丝数 ：',
          value: null,
        },
      ]
      return arr.map((it) => {
        it.value = this.shopInfo[it.key]
        return it
      })
    },
    statRows() {
      return this.shopInfo.last30_lottery_stat?.length !== 0
        ? this.shopInfo.last30_lottery_stat
        : null
    },
  },
  methods: {
    showImg(lotName) {
      return require(`@/assets/lot-logo/${lotName}.png`)
    },
    showPreferredLots(jsonStr) {
      if (jsonStr) {
        const json = JSON.parse(jsonStr)
        if (json.length === 0) {
          return '无'
        } else {
          return json.map((it) => it.bindValue[1]).join(',')
        }
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/views/Member/Store/Store.scss';
#StoreInfo {
  .infoBlock {
    padding: 22px;
    color: #5d5d5d;
    border: 1px solid #d7d7d7;
    .dataRow {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      text-align: left;
      line-height: 1.5rem;
      font-size: 1rem;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .statRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: left;
    line-height: 1.5rem;
    font-size: 1rem;
    margin-bottom: 20px;
    margin-left: 5px;
    .statRowCol {
      margin-right: 25px;
    }
    .lotCol {
      width: 165px;
    }
    .winText {
      color: #ff5757;
      margin-left: 5px;
    }
    img {
      width: 50px;
      height: 50px;
      margin-right: 16px;
    }
  }
}
</style>
